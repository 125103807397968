import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types
}

const defaultProps = {
  children: null,
  ...SectionProps.defaults
}

const BottomSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader1 = {
    title: '\"Oh my god. Thank you!\"',
    paragraph: '@pkorpak'
  };

  const sectionHeader2 = {
    title: '\"Oh my god. Thank you!\"',
    paragraph: '@pkorpak'
  };

  const sectionHeader3 = {
    title: '\"Oh my god. Thank you!\"',
    paragraph: '@pkorpak'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>


        <SectionHeader data={sectionHeader1} className="center-content" />
        <SectionHeader data={sectionHeader2} className="center-content" />
        <SectionHeader data={sectionHeader3} className="center-content" />

        </div>
      </div>

    </section>
  );
}

BottomSection.propTypes = propTypes;
BottomSection.defaultProps = defaultProps;

export default BottomSection;

import React from 'react';
// import sections
import PrivacyDocument from '../components/sections/PrivacyDocument';

const Home = () => {

  return (
    <>
      <PrivacyDocument />
    </>
  );
}

export default Home;
